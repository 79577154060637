<template>
  <span v-if="checked" class="checked">
    <img src="../../public/img/kw-green-check.svg" alt="icon" />
  </span>
  <span v-else class="times">
    <img src="../../public/img/kw-times.svg" alt="icon" />
  </span>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "user-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    checked: {
      type: Boolean,
      default: false,
      description: "check success or danger",
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},
};
</script>

<style lang="scss">
span {
  &.checked {
    img {
      width: 20px;
    }
  }
  &.times {
    img {
      width: 20px;
    }
  }
}
</style>
