<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div class="add-form-inner">
      <div class="form-wrapper title">
        <h2>{{ $t("COMMON.ROLE_INFORMATION") }}</h2>
      </div>

      <div
        class="form-wrapper full"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)"
      >
        <base-input
          :label="$t('COMMON.RESELLER')"
          :placeholder="$t('COMMON.RESELLER')"
        >
          <reseller-selector
            :reseller="role.reseller ? role.reseller.id : null"
            :filterable="true"
            :showAll="false"
            :allowNone="true"
            @resellerChanged="
              (resellerId) => {
                if (role.reseller) {
                  role.reseller.id = resellerId;
                } else {
                  role.reseller = { type: 'resellers', id: resellerId };
                }

                if (role.organization) {
                  role.organization.id = null;
                } else {
                  role.organization = { type: 'organizations', id: null };
                }
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.reseller" />
      </div>

      <div
        class="form-wrapper full"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <base-input
          :label="$t('COMMON.ORGANIZATION')"
          :placeholder="$t('COMMON.ORGANIZATION')"
        >
          <organization-selector
            :organization="role.organization ? role.organization.id : null"
            :filterable="true"
            :showAll="false"
            :allowNone="true"
            :filterReseller="role.reseller ? role.reseller.id : null"
            @organizationChanged="
              (organizationId) => {
                role.organization.id = organizationId;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.organization" />
      </div>

      <div
        class="form-wrapper full"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
      >
        <base-input
          :label="`${$t('COMMON.LOCATION')}`"
          :placeholder="$t('COMMON.LOCATION')"
        >
          <locations-selector
            :locations="role.allowedLocations"
            :filterable="true"
            :showAll="false"
            :multiple="true"
            :organization="role.organization ? role.organization.id : null"
            @locationsChanged="
              (locations) => {
                role.allowedLocations = locations;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.location" />
      </div>

      <div class="form-wrapper full">
        <base-input
          :label="`${$t('COMMON.NAME')} (*)`"
          :placeholder="$t('COMMON.NAME')"
          v-model="role.name"
          @change="onFormChanged"
        />
        <validation-error :errors="apiValidationErrors.name" />
      </div>

      <div class="form-wrapper full">
        <base-input
          :label="$t('COMMON.EXCERPT')"
          :placeholder="$t('COMMON.EXCERPT')"
        >
          <html-editor v-model="role.excerpt" @change="onFormChanged()">
          </html-editor>
        </base-input>
        <validation-error :errors="apiValidationErrors.excerpt" />
      </div>

      <div class="form-wrapper full permissions-select">
        <h2>
          {{ `${$t("COMMON.PERMISSIONS")} (*)` }}
        </h2>
        <div
          v-for="model in modelsPermissions"
          :key="model.name"
          class="permissions-select-wrapper"
        >
          <h3>
            {{ $t(`COMMON.${model.name}`) }}
          </h3>
          <div class="permissions-select-wrapper-list">
            <div
              v-for="permission in model.permissions"
              :key="permission.key"
              class="permissions-select-wrapper-list-item"
            >
              <el-switch
                v-model="permissionsModel[permission.id].checked"
                active-color="#576654"
                inactive-color="#E5E7EB"
                :active-text="$t(`PERMISSIONS.${permission.key}`)"
              >
              </el-switch>
            </div>
          </div>
        </div>
      </div>
      <validation-error :errors="apiValidationErrors.permissions" />
    </div>

    <div class="submit-wrapper">
      <base-button class="btn cancel" type="button" @click="onCloseRoleModal">
        {{ $t("COMMON.CANCEL") }}
      </base-button>
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
        {{ role.id ? $t("COMMON.EDIT") : $t("COMMON.ADD_ITEM") }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option, Switch } from "element-ui";
import swal from "sweetalert2";
import formMixin from "@/mixins/form-mixin";
import models from "@/constants/models";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import ResellerSelector from "@/components/ResellerSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import { ref } from "vue";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    OrganizationSelector,
    ResellerSelector,
    LocationsSelector,
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch,
  },

  mixins: [formMixin],

  props: ["roleData", "formErrors", "loading"],

  data() {
    let roleData = { ...this.roleData };
    roleData = this.$fillUserOrganizationData(roleData);
    return {
      role: roleData,
      permissionsList: [],
      permissionsModel: {},
      models: models,
    };
  },

  created() {
    this.getPermissions();
  },

  computed: {
    modelsPermissions() {
      const modelsPerms = [];
      const foundPerms = [];
      for (const model in models) {
        const permissions = [];
        for (const perm of this.permissionsList) {
          if (!foundPerms.includes(perm.key)) {
            let modelPermKey = `_${model}`;
            if (model == models.APP) {
              modelPermKey = `_${model}_`;
            }
            if (perm.key.includes(modelPermKey)) {
              permissions.push(perm);
              foundPerms.push(perm.key);
            }
          }
        }
        if (permissions.length > 0) {
          modelsPerms.push({ name: model, permissions: permissions });
        }
      }
      return modelsPerms;
    },
  },

  methods: {
    async getPermissions() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        let params = {
          sort: "name",
          page: {
            number: 1,
            size: 999999,
          },
          filter: {},
        };
        if (this.role.organization?.id) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.role.organization?.id,
            },
          };
        }
        await this.$store.dispatch("permissions/list", params);
        this.permissionsList = await this.$store.getters["permissions/list"];
        const permissionsModelData = {};
        for (const permission of this.permissionsList) {
          if (this.role.permissions.find((perm) => perm.id === permission.id)) {
            permissionsModelData[permission.id] = {
              type: "permissions",
              id: permission.id,
              checked: true,
            };
          } else {
            permissionsModelData[permission.id] = {
              type: "permissions",
              id: permission.id,
              checked: false,
            };
          }
        }
        this.permissionsModel = cloneDeep(permissionsModelData);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }

      swal.close();
    },

    async copyPermissions() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      try {
        await this.$store.dispatch("roles/get", this.copyRole.id);
        const role = this.$store.getters["roles/role"];
        for (const permId in this.permissionsModel) {
          if (this.permissionsModel[permId]) {
            this.permissionsModel[permId].checked = false;
          }
        }
        for (const permission of role.permissions) {
          if (this.permissionsModel[permission.id]) {
            this.permissionsModel[permission.id].checked = true;
          }
        }
        this.permissionsModel = cloneDeep(this.permissionsModel);
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
      swal.close();
    },

    async handleSubmit() {
      let roleData = cloneDeep(this.role);
      roleData = this.$fillUserOrganizationData(roleData);

      const permissionsData = [];
      for (const permissionId in this.permissionsModel) {
        if (this.permissionsModel[permissionId].checked) {
          permissionsData.push({ type: "permissions", id: permissionId });
        }
      }
      roleData.permissions = permissionsData;
      if (roleData.organization) {
        if (!roleData.organization.id) {
          delete roleData.organization;
        }
      }
      if (roleData.reseller) {
        if (!roleData.reseller.id) {
          delete roleData.reseller;
        }
      }

      this.$emit("roleSubmitted", roleData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    onCloseRoleModal() {
      this.$emit("onCloseRoleModal");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    roleData(roleData) {
      if (roleData) {
        this.role = { ...this.role, ...cloneDeep(roleData) };
        if (!this.role.organization) {
          this.role.organization = {
            type: "organizations",
            id: null,
          };
        }
        if (!this.role.reseller) {
          this.role.reseller = {
            type: "resellers",
            id: null,
          };
        }
        this.getPermissions();
      }
    },
  },
};
</script>
